body,
html {
  margin: 0;
  font-family: "Inter", sans-serif;
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

@media only screen and (max-width: 767px) {
  body {
    overflow-y: scroll;
    overflow-x: hidden !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button:hover .white-on-hover {
  filter: brightness(0) invert(1);
}

a {
  text-decoration: none;
}

.paragraph-link {
  color: #6c6c73;
  text-decoration: underline;
}

a:hover {
  cursor: pointer;
  color: #007aff;
  transition: color 200ms ease-in;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ecosystem-slide-fade-enter {
  opacity: 0;
  transform: translateX(100%);
}
.ecosystem-slide-fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.ecosystem-slide-fade-exit {
  opacity: 1;
  transform: translateX(0%);
}
.ecosystem-slide-fade-exit-active {
  opacity: 0;
  transform: translateX(-100%);
}
.ecosystem-slide-fade-enter-active,
.ecosystem-slide-fade-exit-active {
  transition: opacity 250ms, transform 250ms;
}

.gradient-button-enter {
  opacity: 0;
}
.gradient-button-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}
.gradient-button-exit {
  opacity: 1;
}
.gradient-button-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-in;
}
